export default {
  authToken: localStorage.getItem("apollo-token") || "",
  _modals: [],
  roles: [],
  users: [],
  permissions: [],
  request_statuses: [],
  passes_statuses: [],
  users_statuses: [],
  reasons: [],
  notifications: {},
  requests: {},
  accesses: {},
  contractors: {},
  me: null,
};
