<template>
  <div class="modal" v-if="$store.state._modals.length">
    <div class="modal__content" v-for="(modal, index) in $store.state._modals" :key="index">
      <div class="modal__background" @click="close(index)"></div>
      <div class="modal__body">
        <component
          :is="modal.component"
          @close="close(index)"
          :options="modal.options"
          @keyup.esc.native="close"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalComponent",
  methods: {
    close(i) {
      return this.$store.state._modals.splice(i, 1);
    },
  },
  watch: {
    "$store.state._modals.length"(val) {
      if (val > 0) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/modal.styl"
</style>
