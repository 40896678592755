import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Requests",
    component: () => import("@/views/requests/list/index"),
    meta: {
      authRequired: true,
      authNoAccessRedirect: "Account",
      authRequiredRoles: [
        "supplier",
        "provider",
        "contractor",
        "admin",
        "supplier_leader",
        "warehouse_manager",
        "warehouse_specialist",
        "passport_office",
        "passport_office_leader",
        "security",
      ],
    },
  },
  {
    path: "/request/:id(\\d+)",
    name: "Request",
    component: () => import("@/views/requests/open/index"),
    meta: {
      authRequired: true,
      authNoAccessRedirect: "Requests",
      authRequiredRoles: [
        "supplier",
        "provider",
        "contractor",
        "admin",
        "supplier_leader",
        "warehouse_manager",
        "warehouse_specialist",
        "passport_office",
        "passport_office_leader",
        "security",
      ],
    },
  },
  {
    path: "/passes",
    name: "Passes",
    component: () => import("@/views/passes/list"),
    meta: {
      authRequired: true,
      authNoAccessRedirect: "Account",
      authRequiredRoles: [
        "sv",
        "admin",
        "supplier_leader",
        "supplier",
        "warehouse_manager",
        "warehouse_specialist",
        "passport_office",
        "passport_office_leader",
        "security",
        "provider",
        "contractor",
      ],
    },
  },
  {
    path: "/pass/:id(\\d+)",
    name: "Pass",
    component: () => import("@/views/passes/open"),
    meta: {
      authRequired: true,
      authNoAccessRedirect: "Account",
      authRequiredRoles: [
        "sv",
        "admin",
        "supplier_leader",
        "supplier",
        "warehouse_manager",
        "warehouse_specialist",
        "passport_office",
        "passport_office_leader",
        "security",
        "provider",
        "contractor",
      ],
    },
  },
  {
    path: "/request/create",
    name: "RequestCreate",
    component: () => import("@/views/requests/create/index"),
    meta: {
      authRequired: true,
      authNoAccessRedirect: "Requests",
      authRequiredRoles: ["provider"],
    },
  },
  {
    path: "/request/create/:id(\\d+)",
    name: "RequestCreateContinue",
    component: () => import("@/views/requests/open/index"),
    meta: {
      authRequired: true,
      authNoAccessRedirect: "Requests",
      authRequiredRoles: ["provider"],
    },
  },
  {
    path: "/contractors",
    name: "Contractors",
    component: () => import("@/views/contractors/index"),
    meta: {
      authRequired: true,
      authNoAccessRedirect: "Account",
      authRequiredRoles: ["sv", "admin", "supplier_leader", "supplier"],
    },
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: () => import("@/views/notifications/index"),
    meta: {
      authRequired: true,
      authNoAccessRedirect: "Notifications",
      authRequiredRoles: "all",
    },
  },
  {
    path: "/account",
    name: "Account",
    component: () => import("@/views/account/index"),
    meta: {
      authRequired: true,
      authNoAccessRedirect: "Requests",
      authRequiredRoles: "all",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/auth/login"),
  },
  {
    path: "/registration",
    name: "Registration",
    component: () => import("@/views/auth/registration"),
  },
  {
    path: "/reset",
    name: "RestorePassword",
    component: () => import("@/views/auth/restore"),
  },
  {
    path: "/reset/:token",
    name: "ResetPassword",
    component: () => import("@/views/auth/reset"),
  },
  {
    path: "*",
    redirect: "login",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
