import UPLOAD_DOCUMENT from "@/graphql/UploadDocument.graphql";
import AllTypes from "@/graphql/Type/AllTypes.graphql";
import AllReasons from "@/graphql/Reason/AllReasons.graphql";
import SEARCH from "@/graphql/Search.graphql";

async function UPLOAD(store, { variables = {}, context }) {
  return await context.$apollo.mutate({
    mutation: UPLOAD_DOCUMENT,
    variables: variables,
    context: {
      useMultipart: true,
    },
  });
}

async function GET_TYPES(_, { variables = {}, context }) {
  return await context.$apollo.mutate({
    mutation: AllTypes,
    variables: variables,
  });
}

async function GET_REASONS({ commit }, { variables = {}, context }) {
  return await context.$apollo.mutate({
    mutation: AllReasons,
    variables: variables,
    update: (store, { data }) => {
      commit("setReasons", data);
    },
  });
}

async function SEARCH_REQUEST(_, { variables = {}, context }) {
  return await context.$apollo.mutate({
    mutation: SEARCH,
    variables: variables,
  });
}

function notifyGQErrors(_, { context, errors }) {
  errors.forEach((err) => {
    if (err.extensions.category === "validation") {
      Object.keys(err.extensions.validation).forEach((key) => {
        err.extensions.validation[key].forEach((message) => {
          context.$notify({
            title: "Ошибка",
            text: message,
            type: "error",
            duration: 6000,
          });
        });
      });
    }
  });
}

export { UPLOAD, notifyGQErrors, GET_TYPES, GET_REASONS, SEARCH_REQUEST };
