<template>
  <figure class="icon burger-menu-icon">
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4" y="7.5" width="24" height="3" rx="1.5" fill="#272525"></rect>
      <rect x="4" y="14.5" width="24" height="3" rx="1.5" fill="#272525"></rect>
      <rect x="4" y="21.5" width="24" height="3" rx="1.5" fill="#272525"></rect>
    </svg>
  </figure>
</template>

<script>
export default {
  name: "BurgerMenuIcon",
};
</script>
