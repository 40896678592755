import ALL from "@/graphql/Requests/All.graphql";
import PAGINATE_ACCESSES from "@/graphql/Requests/PaginateAccesses.graphql";
import AllRequestStatuses from "@/graphql/Status/AllRequestStatuses.graphql";
import ONE_REQUEST from "@/graphql/Requests/OneRequest.graphql";
import ONE_ACCESS from "@/graphql/Requests/OneAccess.graphql";
import ONE_ACCESS_BY_UUID from "@/graphql/Requests/OneAccessByUuid.graphql";
import REQUEST_FIRST_STEP from "@/graphql/Requests/mutations/RequestFirstStep.graphql";
import REQUEST_SECOND_STEP from "@/graphql/Requests/mutations/RequestSecondStep.graphql";
import REQUEST_SEND_STEP from "@/graphql/Requests/mutations/RequestSendStep.graphql";
import ACCEPT_REQUEST from "@/graphql/Requests/mutations/AcceptRequest.graphql";
import ACCEPT_ACCESS from "@/graphql/Requests/mutations/AcceptAccess.graphql";
import DENY_ACCESS from "@/graphql/Requests/mutations/DenyAccess.graphql";
import EDIT_DELIVERY_INFO from "@/graphql/Requests/mutations/EditDeliveryInfo.graphql";
import AllCarBrands from "@/graphql/Car/AllCarBrands.graphql";
import AllWarehouses from "@/graphql/Warehouse/AllWarehouses.graphql";
import AllOptions from "@/graphql/Option/AllOptions.graphql";
import AllUnloading from "@/graphql/Unloading/AllUnloading.graphql";
import AllShipments from "@/graphql/Shipment/AllShipments.graphql";
import FILTER_CONTRACT from "@/graphql/Contracts/FilterContract.graphql";
import CARGO_INPUTS from "@/graphql/QueriesByPage/cargoInputs.graphql";
import VEHICLE_INPUTS from "@/graphql/QueriesByPage/vehicleInputs.graphql";
import REQUESTS_LIST_ACTIONS from "@/graphql/QueriesByPage/requestsListActions.graphql";
import PASSES_LIST_ACTIONS from "@/graphql/QueriesByPage/passesListActions.graphql";
import PAGINATE_AGREEMENTS_QUERY from "@/graphql/Agreement/PaginateAgreements.graphql";

async function GET_REQUESTS({ commit }, { variables, context }) {
  await context.$apollo.mutate({
    mutation: ALL,
    variables: Object.assign(variables),
    update: (store, { data }) => {
      commit("setRequests", data);
    },
  });
}

async function GET_PASSES({ commit }, { variables, context }) {
  await context.$apollo.mutate({
    mutation: PAGINATE_ACCESSES,
    variables: Object.assign(variables),
    update: (store, { data }) => {
      commit("setPasses", data);
    },
  });
}

async function GET_REQUESTS_STATUSES({ commit }, { variables = {}, context }) {
  await context.$apollo.mutate({
    mutation: AllRequestStatuses,
    variables: Object.assign(variables),
    update: (store, { data }) => {
      commit("setRequestStatuses", data);
    },
  });
}

async function GET_REQUEST(_, { variables, context }) {
  return await context.$apollo.mutate({
    mutation: ONE_REQUEST,
    variables: variables,
  });
}

async function GET_PASS(_, { variables, context }) {
  return await context.$apollo.mutate({
    mutation: ONE_ACCESS,
    variables: variables,
  });
}

async function GET_PASS_BY_UUID(_, { variables, context }) {
  return await context.$apollo.mutate({
    mutation: ONE_ACCESS_BY_UUID,
    variables: variables,
  });
}

async function CREATE_REQUEST(_, { variables, context }) {
  return await context.$apollo.mutate({
    mutation: REQUEST_FIRST_STEP,
    variables: variables,
  });
}

async function SAVE_REQUEST(_, { variables, context }) {
  return await context.$apollo.mutate({
    mutation: REQUEST_SECOND_STEP,
    variables: variables,
  });
}

async function EDIT_DELIVERY(_, { variables, context }) {
  return await context.$apollo.mutate({
    mutation: EDIT_DELIVERY_INFO,
    variables: variables,
  });
}

async function SEND_REQUEST(_, { variables, context }) {
  return await context.$apollo.mutate({
    mutation: REQUEST_SEND_STEP,
    variables: variables,
  });
}

async function ACCEPT_PASS(_, { variables, context }) {
  return await context.$apollo.mutate({
    mutation: ACCEPT_ACCESS,
    variables: variables,
  });
}

async function DENY_PASS(_, { variables, context }) {
  return await context.$apollo.mutate({
    mutation: DENY_ACCESS,
    variables: variables,
  });
}

async function ACCEPT_OR_DECLINE_REQUEST(_, { variables, context }) {
  return await context.$apollo.mutate({
    mutation: ACCEPT_REQUEST,
    variables: variables,
  });
}

async function GET_CAR_BRANDS(_, { variables = {}, context }) {
  return await context.$apollo.mutate({
    mutation: AllCarBrands,
    variables: variables,
  });
}

async function GET_WAREHOUSES(_, { variables = {}, context }) {
  return await context.$apollo.mutate({
    mutation: AllWarehouses,
    variables: variables,
  });
}

async function GET_OPTIONS(_, { variables = {}, context }) {
  return await context.$apollo.mutate({
    mutation: AllOptions,
    variables: variables,
  });
}

async function GET_UNLOADING(_, { variables = {}, context }) {
  return await context.$apollo.mutate({
    mutation: AllUnloading,
    variables: variables,
  });
}

async function GET_SHIPMENTS(_, { variables = {}, context }) {
  return await context.$apollo.mutate({
    mutation: AllShipments,
    variables: variables,
  });
}

async function FILTER_CONTRACTS(_, { variables, context }) {
  return await context.$apollo.mutate({
    mutation: FILTER_CONTRACT,
    variables: Object.assign(variables),
  });
}

async function PAGINATE_AGREEMENTS(_, { variables, context }) {
  return await context.$apollo.mutate({
    mutation: PAGINATE_AGREEMENTS_QUERY,
    variables: Object.assign(variables),
  });
}

async function GET_CREATE_REQUEST_CARGO_INPUTS(_, { context }) {
  return await context.$apollo.mutate({
    mutation: CARGO_INPUTS,
  });
}

async function GET_CREATE_REQUEST_VEHICLE_INPUTS(_, { context }) {
  return await context.$apollo.mutate({
    mutation: VEHICLE_INPUTS,
  });
}

async function GET_REQUEST_LIST_ACTIONS_DATA({ commit }, { context }) {
  return await context.$apollo.mutate({
    mutation: REQUESTS_LIST_ACTIONS,
    update: (store, { data }) => {
      commit("setRequestListActions", data);
    },
  });
}

async function GET_PASSES_LIST_ACTIONS_DATA({ commit }, { context }) {
  return await context.$apollo.mutate({
    mutation: PASSES_LIST_ACTIONS,
    update: (store, { data }) => {
      commit("setPassesListActions", data);
    },
  });
}

export {
  PAGINATE_AGREEMENTS,
  GET_CREATE_REQUEST_CARGO_INPUTS,
  GET_CREATE_REQUEST_VEHICLE_INPUTS,
  GET_REQUEST_LIST_ACTIONS_DATA,
  FILTER_CONTRACTS,
  GET_REQUESTS,
  CREATE_REQUEST,
  GET_REQUEST,
  SAVE_REQUEST,
  SEND_REQUEST,
  GET_PASSES,
  ACCEPT_OR_DECLINE_REQUEST,
  EDIT_DELIVERY,
  GET_PASS,
  GET_PASS_BY_UUID,
  ACCEPT_PASS,
  DENY_PASS,
  GET_REQUESTS_STATUSES,
  GET_CAR_BRANDS,
  GET_OPTIONS,
  GET_WAREHOUSES,
  GET_UNLOADING,
  GET_SHIPMENTS,
  GET_PASSES_LIST_ACTIONS_DATA,
};
