import Vue from "vue";
import VueApollo from "vue-apollo";
import { createApolloClient } from "vue-cli-plugin-apollo/graphql-client";

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = "apollo-token";

// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || "http://localhost:4000/graphql";
// // Files URL root
// export const filesRoot =
//   process.env.VUE_APP_FILES_ROOT ||
//   httpEndpoint.substr(0, httpEndpoint.indexOf("/graphql"));
//
// Vue.prototype.$filesRoot = filesRoot;

// Config
const defaultOptions = {
  httpEndpoint,
  wsEndpoint: null, //process.env.VUE_APP_GRAPHQL_WS || "ws://localhost:4000/graphql",
  tokenName: AUTH_TOKEN,
  persisting: false,
  websocketsOnly: false,
  ssr: false,
};

// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create apollo client
  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options,
  });
  apolloClient.wsClient = wsClient;

  // Create vue apollo provider
  return new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: "cache-and-network",
      },
    },
  });
}
