function setAuthToken(state, token) {
  state.authToken = token;
  localStorage.setItem("apollo-token", token);
}

function removeAuthToken(state) {
  state.authToken = null;
  localStorage.removeItem("apollo-token");
}

function setRoles(state, roles) {
  if (roles && roles.AllRoles && roles.AllRoles.length) {
    state.roles = roles.AllRoles;
  }
}

function setUsers(state, users) {
  if (users && users.AllUsers && users.AllUsers.length) {
    state.users = users.AllUsers;
  }
}

function setNotifications(state, data) {
  if (data && data.PaginateNotifications) {
    state.notifications = data.PaginateNotifications;
  }
}

function setRegistration(state, data) {
  if (data) {
    if (data.AllRoles && data.AllRoles.length) {
      state.roles = data.AllRoles.filter((r) => r.type.code === 2);
      state.permissions = data.AllRoles.filter((r) => r.type.code === 3);
    }
    if (data.AllUsers && data.AllUsers.length) {
      state.users = data.AllUsers;
    }
  }
}

function setPartnerEdit(state, data) {
  if (data) {
    if (data.roles && data.roles.length) {
      state.roles = data.roles;
    }
    if (data.permissions && data.permissions.length) {
      state.permissions = data.permissions;
    }
    if (data.AllUsers && data.AllUsers.length) {
      state.users = data.AllUsers;
    }
  }
}

function setContractorsListActions(state, data) {
  if (data && data.AllRoles && data.AllRoles.length) {
    state.roles = data.AllRoles;
  }
}

function setContractors(state, users) {
  if (users && users.PaginateUsers) {
    state.contractors = users.PaginateUsers;
  }
}

function setMe(state, data) {
  if (data && data.Me) {
    state.me = data.Me;
  }
}

function setPermissions(state, permissions) {
  if (permissions && permissions.AllRoles && permissions.AllRoles.length) {
    state.permissions = permissions.AllRoles;
  }
}

export {
  setAuthToken,
  setRoles,
  setUsers,
  setPermissions,
  setMe,
  setRegistration,
  setPartnerEdit,
  setNotifications,
  setContractorsListActions,
  removeAuthToken,
  setContractors,
};
