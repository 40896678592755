function setRequests(state, requests) {
  if (requests && requests.AllRequests) {
    state.requests = requests.AllRequests;
  }
}

function setPasses(state, data) {
  if (data && data.PaginateAccesses) {
    state.accesses = data.PaginateAccesses;
  }
}

function setRequestListActions(state, data) {
  if (data) {
    if (data.AllRequestStatuses && data.AllRequestStatuses.length) {
      state.request_statuses = data.AllRequestStatuses;
    }
    if (data.AllUsers && data.AllUsers.length) {
      state.users = data.AllUsers;
    }
  }
}

function setPassesListActions(state, data) {
  if (data) {
    if (data.AllAccessesStatuses && data.AllAccessesStatuses.length) {
      state.passes_statuses = data.AllAccessesStatuses;
    }
    if (data.AllUsers && data.AllUsers.length) {
      state.users = data.AllUsers;
    }
  }
}

function setRequestStatuses(state, statuses) {
  if (statuses && statuses.AllRequestStatuses && statuses.AllRequestStatuses.length) {
    state.request_statuses = statuses.AllRequestStatuses;
  }
}

export { setRequests, setRequestListActions, setRequestStatuses, setPassesListActions, setPasses };
