<template>
  <div id="app">
    <vue-progress-bar />
    <HeaderComponent v-if="showHeader" />
    <RouterView />
    <ModalComponent />
    <NotifyComponent position="bottom right" />
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent";
import ModalComponent from "@/components/ModalComponent";

export default {
  created() {
    this.$Progress.start();
    this.$router.beforeEach((to, from, next) => {
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress;
        this.$Progress.parseMeta(meta);
      }
      this.$Progress.start();
      next();
    });
    this.$router.afterEach(() => {
      this.$Progress.finish();
    });
  },
  mounted() {
    this.$Progress.finish();
  },
  computed: {
    showHeader() {
      const banned = ["Login", "Registration", "RestorePassword", "ResetPassword"];
      return !banned.includes(this.$route.name);
    },
  },
  components: { ModalComponent, HeaderComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/style"
</style>
