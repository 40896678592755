import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import store from "./store";
import filters from "./filters";
import VueProgressBar from "vue-progressbar";
import VueMask from "v-mask";
import Vuelidate from "vuelidate";
import Notifications from "vue-notification";
import VueMq from "vue-mq";
import { createProvider } from "./vue-apollo";

const apolloProvider = createProvider();
import router from "./router";

Vue.config.productionTip = false;

Vue.use(VueProgressBar, {
  color: "#8EBD3B",
  failedColor: "#FB4E4E",
  thickness: "5px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  inverse: false,
});
Vue.use(Notifications, {
  componentName: "NotifyComponent",
});
Vue.use(VueMq, {
  breakpoints: {
    sm: 450,
    md: 1241,
    lg: Infinity,
  },
});
Vue.use(VueMask);
Vue.use(Vuelidate);

function choosePath(to, from, next) {
  if (to.meta.authRequiredRoles === "all") {
    next();
    return;
  }
  const canShow = to.meta.authRequiredRoles.some((role) => {
    return store.state.me.role.map((r) => r.name).includes(role);
  });
  if (!canShow) {
    next({ name: to.meta.authNoAccessRedirect });
    return;
  }
  next();
}

router.beforeEach((to, from, next) => {
  if (!store.state.authToken && to.meta.authRequired) {
    next({ name: "Login" });
  } else if (to.meta && to.meta.authRequired) {
    if (store.state.me) {
      choosePath(to, from, next);
    } else {
      store
        .dispatch("GET_ME", {
          context: router.app,
        })
        .then(() => {
          choosePath(to, from, next);
        });
    }
  } else {
    next();
  }
});

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

new Vue({
  router,
  store,
  apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
