<template>
  <div
    class="search"
    :style="`top:${options.rect.top}px;left:${options.rect.left}px;width:${options.rect.width}px`"
  >
    <div
      class="search__input"
      :class="{
        'search__input--show-clear': showClose,
        'search__input--show-drop': showDrop,
      }"
    >
      <button type="button" class="search__input-btn" v-if="!showClose">
        <SearchIcon />
      </button>
      <button type="button" class="search__input-btn" v-else @click="search = null">
        <LoaderIcon v-if="loading" class="loading-rotate" />
        <CloseIcon v-else />
      </button>
      <input type="text" class="search__input-field" placeholder="Поиск" ref="input" v-model="search" />
    </div>
    <div class="search__drop" v-if="showDrop">
      <div class="search__type" v-if="loading">
        <div class="search__type-title" :class="{ 'search__type-title--loading': loading }">
          <LoadingIndicator />
        </div>
      </div>
      <template v-else>
        <div class="search__type" v-if="showClaims">
          <div class="search__type-title">Заявки</div>
          <div class="search__list">
            <span
              class="search__list-item search__list-item--empty"
              v-if="requests.data && !requests.data.length"
            >
              Ничего не найдено
            </span>
            <router-link
              :to="{ name: 'Request', params: { id: item.id } }"
              :key="i"
              v-for="(item, i) in requests.data"
              class="search__list-item"
            >
              Заявка №{{ item.id }}
            </router-link>
            <router-link
              :to="{ name: 'Requests' }"
              v-if="requests.paginatorInfo && requests.paginatorInfo.total > maxItems"
              class="search__list-item search__list-item--all"
            >
              Показать все ({{ requests.paginatorInfo.total }})
            </router-link>
          </div>
        </div>
        <div class="search__type" v-if="showAccesses">
          <div class="search__type-title">Пропуска</div>
          <div class="search__list">
            <span
              class="search__list-item search__list-item--empty"
              v-if="accesses.data && !accesses.data.length"
            >
              Ничего не найдено
            </span>
            <router-link
              :to="{ name: 'Pass', params: { id: item.id } }"
              :key="i"
              v-for="(item, i) in accesses.data"
              class="search__list-item"
            >
              Пропуск №{{ item.id }}
            </router-link>
            <router-link
              :to="{ name: 'Passes' }"
              v-if="accesses.paginatorInfo && accesses.paginatorInfo.total > maxItems"
              class="search__list-item search__list-item--all"
            >
              Показать все ({{ accesses.paginatorInfo.total }})
            </router-link>
          </div>
        </div>
        <div class="search__type" v-if="showContractors">
          <div class="search__type-title">Поставщики</div>
          <div class="search__list">
            <span
              class="search__list-item search__list-item--empty"
              v-if="providers.data && !providers.data.length"
            >
              Ничего не найдено
            </span>
            <router-link
              :to="{ name: 'Contractors', params: { id: item.id } }"
              :key="i"
              v-for="(item, i) in providers.data"
              class="search__list-item"
            >
              {{ item | getCompany }}
            </router-link>
            <router-link
              :to="{ name: 'Contractors' }"
              v-if="providers.paginatorInfo && providers.paginatorInfo.total > maxItems"
              class="search__list-item search__list-item--all"
            >
              Показать все ({{ providers.paginatorInfo.total }})
            </router-link>
          </div>
        </div>
        <div class="search__type" v-if="showContractors">
          <div class="search__type-title">Подрядчики</div>
          <div class="search__list">
            <span
              class="search__list-item search__list-item--empty"
              v-if="contractors.data && !contractors.data.length"
            >
              Ничего не найдено
            </span>
            <router-link
              :to="{ name: 'Contractors', params: { id: item.id } }"
              :key="i"
              v-for="(item, i) in contractors.data"
              class="search__list-item"
            >
              {{ item | getCompany }}
            </router-link>
            <router-link
              :to="{ name: 'Contractors' }"
              v-if="contractors.paginatorInfo && contractors.paginatorInfo.total > maxItems"
              class="search__list-item search__list-item--all"
            >
              Показать все ({{ contractors.paginatorInfo.total }})
            </router-link>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import SearchIcon from "./svg/SearchIcon.vue";
import CloseIcon from "./svg/CloseIcon.vue";
import LoaderIcon from "./svg/LoaderIcon.vue";
import LoadingIndicator from "./LoadingIndicator.vue";

export default {
  name: "SearchComponent",
  props: {
    options: Object,
  },
  data() {
    return {
      maxItems: 5,
      search: null,
      searchTimeout: null,
      showDrop: false,
      showClose: false,
      loading: false,
      requests: {},
      accesses: {},
      providers: {},
      contractors: {},
    };
  },
  watch: {
    search(val) {
      if (val && val.length) {
        this.showDrop = true;
        this.showClose = true;
        this.loading = true;
        this.searchRequest();
        return;
      }
      this.loading = false;
      clearTimeout(this.searchTimeout);
      this.providers = {};
      this.contractors = {};
      this.showDrop = false;
      this.showClose = false;
    },
  },
  mounted() {
    this.$refs.input.focus();
  },
  computed: {
    me() {
      return this.$store.state.me;
    },
    showClaims() {
      if (this.me && this.me.role && this.me.role.length) {
        return !!this.me.role.filter((r) => {
          return [
            "supplier",
            "provider",
            "contractor",
            "admin",
            "supplier_leader",
            "warehouse_manager",
            "warehouse_specialist",
            "passport_office",
            "passport_office_leader",
            "security",
          ].includes(r.name);
        }).length;
      }
      return false;
    },
    showAccesses() {
      if (this.me && this.me.role && this.me.role.length) {
        return !!this.me.role.filter((r) => {
          return [
            "sv",
            "admin",
            "supplier_leader",
            "supplier",
            "warehouse_manager",
            "warehouse_specialist",
            "passport_office",
            "passport_office_leader",
            "security",
            "provider",
            "contractor",
          ].includes(r.name);
        }).length;
      }
      return false;
    },
    showContractors() {
      if (this.me && this.me.role && this.me.role.length) {
        return !!this.me.role.filter((r) => {
          return ["sv", "admin", "supplier_leader", "supplier"].includes(r.name);
        }).length;
      }
      return false;
    },
  },
  methods: {
    searchRequest() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.$store
          .dispatch("SEARCH_REQUEST", {
            context: this,
            variables: {
              first: this.maxItems,
              title: this.search,
            },
          })
          .then(({ data }) => {
            if (data.Search) {
              if (data.Search.accesses) this.accesses = data.Search.accesses;
              if (data.Search.requests) this.requests = data.Search.requests;
              if (data.Search.providers) this.providers = data.Search.providers;
              if (data.Search.contractors) this.contractors = data.Search.contractors;
            }
            this.loading = false;
          });
      }, 500);
    },
  },
  components: { LoadingIndicator, LoaderIcon, CloseIcon, SearchIcon },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/svg.styl"
.search {
  position absolute
  width 100%
  display grid
  padding-bottom 30px

  &__input {
    background: var(--main_white);
    border: 1px solid var(--input_focused_border);
    box-sizing: border-box;
    border-radius: var(--radius);
    display: grid;
    grid-template-columns: auto 1fr;
    align-items center

    &--show-clear {
      grid-template-columns: auto 1fr auto;
    }

    &--show-drop {
      border-radius: var(--radius) var(--radius) 0 0;
    }
  }

  &__drop {
    background: var(--main_white);
    border-radius: 0 0 12px 12px;
    display grid
  }

  &__type {
    display grid

    &:not(:last-child) {
      border-bottom 1px solid var(--input_focused_border)
    }
  }

  &__type-title {
    padding 16px
    font-weight 500
    font-size: 1em;
    line-height: 20px;
    color: var(--main_dark_gray);
    background: var(--search_type_title);
    border-bottom 1px solid var(--input_focused_border)

    &--loading {
      border-radius: 0 0 var(--radius) var(--radius);
    }
  }

  &__list {
    display grid
    padding 12px 0 12px 106px
    +below(768px) {
      padding 12px 0 12px 32px
    }
  }

  &__list-item {
    padding 6px 0
    font-weight: 500;
    font-size: 0.8750em;
    line-height: 20px;
    letter-spacing: var(--letter_spacing);
    color: var(--main_dark);
    transition color .3s

    &--empty {
      padding 6px 0
    }

    &:not(&--empty):hover {
      color var(--main_blue)
    }

    &--all {
      padding 12px 0
      font-weight normal
      color var(--main_blue)

      &:hover {
        color var(--main_blue-d40)
      }
    }
  }

  &__input-field {
    height 18px
    padding 0
    font-size: 1em;
    line-height: 20px;
    color: var(--main_dark_gray);
    border none
    outline none
  }

  &__input-btn {
    background none
    border none
    cursor pointer
    padding 15px
    outline none
    border-radius var(--radius)
    z-index 1

    &:first-child {
      padding-right 12px
    }

    &:active
    &:focus {
      box-shadow: 0 0 0 4px var(--main_green_grass);
    }

    .icon {
      width 18px
      height 18px

      svg {
        svg(var(--main_gray))
      }
    }
  }
}
</style>
