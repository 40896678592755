<template>
  <div class="notice" :style="style" v-if="notifications">
    <div class="notice__title">Уведомления</div>
    <div class="notice__list">
      <template v-if="notifications.length">
        <a
          :href="$router.resolve(getRoute(n)).href"
          @click.prevent="readNotification(n)"
          class="notice__item"
          :class="{
            'notice__item--red': !n.read_at,
          }"
          v-for="(n, i) in notifications.slice(0, 5)"
          :key="i"
        >
          <span class="notice__date">{{ n.created_at | formatDate }}</span>
          <span class="notice__type">{{ n.event.name }}</span>
          <span class="notice__name">{{ getFrom(n) }}</span>
        </a>
      </template>
      <div class="notice__empty" v-else>Уведомлений нет</div>
    </div>
    <router-link :to="{ name: 'Notifications' }" class="notice__footer">
      Показать все ({{ notifications.length }})
    </router-link>
  </div>
</template>

<script>
export default {
  name: "NotificationsListComponent",
  props: {
    options: Object,
  },
  data() {
    return {
      loading: false,
      style: `top:${this.options.rect.top + 55}px;left:${this.options.rect.left}px;right:${
        this.options.rect.right
      }px`,
    };
  },
  computed: {
    notifications() {
      if (this.me && this.me.notifications) {
        let notifications = this.me.notifications;
        return notifications.sort((a) => (a.read_at === null ? -1 : 1));
      }
      return [];
    },
    me() {
      return this.$store.state.me;
    },
  },
  methods: {
    readNotification(n) {
      if (n.read_at) {
        this.$router.push(this.getRoute(n));
        return;
      }
      if (!this.loading) {
        this.loading = true;
        this.$emit("event", {
          name: "notificationsLoading",
          value: true,
        });
        this.$store
          .dispatch("MARK_NOTIFICATION_READ", {
            context: this,
            variables: {
              id: n.id,
            },
          })
          .then(() => {
            n.read_at = new Date();
            this.$router.push(this.getRoute(n));
            this.loading = false;
            this.$emit("event", {
              name: "notificationsLoading",
              value: false,
            });
          });
      }
    },
    getRouteName(sendable_type) {
      switch (sendable_type) {
        case "App\\Models\\Request":
          return "Request";
        case "App\\Models\\Access":
          return "Pass";
        default:
          return null;
      }
    },
    getRoute(n) {
      const routeName = this.getRouteName(n.sendable_type);
      if (routeName) {
        return { name: routeName, params: { id: n.sendable_id } };
      }
      return { name: "Notifications" };
    },
    getFrom(n) {
      if (n.user && n.user.company && n.user.company_name) {
        return n.user.company_name;
      }
      if (n.user) {
        return `${n.user.surname} ${n.user.name} ${n.user.patronymic}`;
      }
      return "-";
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="stylus">
.notice {
  display grid
  position absolute
  width: 417px;
  background: var(--main_white);
  border-radius: var(--radius);
  box-shadow: var(--header-drop-shadow);
  transform translateX(calc(-50% + 20px))
  // X - половина ширины себя + половина кнопки уведомлений
  +below(1240px) {
    right 20px !important
    left initial !important
    transform none
  }
  +below(470px) {
    width: calc(100% - 40px);
    right: 0 !important;
    box-sizing: border-box;
    margin: 0 20px;
  }

  &:before {
    content: ""
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--main_white);
    left: 0;
    right: 0;
    margin: auto;
    top: -10px;
    position: absolute;
    z-index: 1;
    +below(1240px) {
      display none
    }
  }

  &__date {
    color: var(--main_default_dark);
  }

  &__type {
    font-weight 500
    margin 4px 0
    color: var(--main_dark);
  }

  &__name {
    font-weight 500
    color: var(--main_default_dark);
  }

  &__title {
    font-weight 500
    font-size: 1em;
    padding 16px
    line-height: 20px;
    color: var(--main_dark_gray);
    width: 100%;
  }


  &__empty {
    @extend .notice__title
    font-weight 400
    border-bottom 1px solid var(--input_focused_border)
  }

  &__list {
    display flex
    flex-direction column
    width: 100%;
    border-top 1px solid var(--input_focused_border)
  }

  &__item {
    padding 12px 12px 12px 44px
    border-bottom 1px solid var(--input_focused_border)
    display grid
    color inherit
    font-size: 0.8750em;
    line-height: 20px;
    letter-spacing: var(--letter_spacing);
    position relative
    cursor pointer

    &:hover {
      border-color var(--input_focused_border)
    }

    &:before {
      absolute left 12px top bottom
      margin auto
      content: ""
      width 8px
      height 8px
      border-radius 100%
      background var(--main_light_gray)
    }

    &--red:before {
      background var(--main_red)
    }
  }

  &__footer {
    text-decoration none
    background none
    border none
    cursor pointer
    font-size: 0.8750em;
    line-height: 20px;
    padding 12px
    width 100%
    text-align center
    letter-spacing: var(--letter_spacing);
    color: var(--main_blue);
  }
}
</style>
