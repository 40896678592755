import moment from "moment";

export default {
  formatDate: function (value) {
    if (value) {
      return moment(value).format("DD.MM.YYYY");
    }
    return "";
  },
  formatTime: function (value) {
    if (value) {
      return moment(value).format("HH:mm");
    }
    return "";
  },
  formatDateTime: function (value) {
    if (value) {
      return moment(value).format("DD.MM.YYYY HH:mm");
    }
    return "";
  },
  getFIO: function (value) {
    return `${value.surname} ${value.name} ${value.patronymic}`;
  },
  getFIOCut: function (value) {
    if (value.name && value.patronymic) {
      return `${value.surname} ${value.name.substr(0, 1)}. ${value.patronymic.substr(0, 1)}.`;
    }
    return value.surname;
  },
  getFIOInitials: function (value) {
    if (value.name && value.patronymic) {
      return `${value.surname.substr(0, 1)}${value.name.substr(0, 1)}${value.patronymic.substr(0, 1)}`;
    }
    return value.surname;
  },
  getCompany: function (value) {
    if (value.company) {
      return value.company.company_name;
    }
  },
  getChildRoles: function (array) {
    if (array && array.length) {
      return array
        .filter((a) => a.parent_id)
        .map((a) => a.name_ru)
        .join(", ");
    }
    return "";
  },
};
