<template>
  <header class="header" v-click-outside="hideDrop" :class="{ 'header--no-tabs': !showTabs }">
    <BurgerMenuIcon />
    <router-link :to="{ name: 'Requests' }">
      <FullLogoIcon />
      <MobileLogoIcon />
    </router-link>
    <ul class="header__tabs" v-if="showTabs">
      <li>
        <router-link
          :to="{ name: 'Requests' }"
          class="header__tab"
          :class="{
            'header__tab--active': $route.name === 'Requests' || $route.name === 'Passes',
          }"
        >
          <FileIcon />
          <span class="header__tab-name">Заявки</span>
        </router-link>
      </li>
      <li v-if="showContractors">
        <router-link
          :to="{ name: 'Contractors' }"
          class="header__tab"
          :class="{
            'header__tab--active': $route.name === 'Contractors',
          }"
        >
          <UsersIcon />
          <span>Контрагенты</span>
        </router-link>
      </li>
    </ul>
    <button class="header__search" ref="input" type="button" @click="showSearch">
      <SearchIcon />
      <span class="header__search-label">Поиск</span>
    </button>
    <div class="header__buttons">
      <button
        class="header__button"
        v-if="me"
        type="button"
        @click="toggleNotifications"
        ref="notificationsButton"
      >
        <LoaderIcon v-if="notificationsLoading" class="loading-rotate" />
        <NotificationOnIcon v-else />
        <span class="header__button-count" v-if="unreadNotifications.length">
          {{ unreadNotifications.length }}
        </span>
      </button>
      <button class="header__button header__button--loading" v-else></button>
      <!--      <button class="header__button">-->
      <!--        <SettingsIcon />-->
      <!--      </button>-->
    </div>
    <div class="header__user">
      <figure class="header__user-img" v-if="me">
        <img :src="me.avatar" v-if="me.avatar" class="image-placeholder" />
        <span class="image-placeholder" v-else>{{ me | getFIOInitials }}</span>
      </figure>
      <figure class="header__user-img header__user-img--loading" v-else></figure>
      <span class="header__user-name" v-if="me">{{ me | getFIOCut }}</span>
      <span class="header__user-name header__user-name--loading" v-else></span>
      <ChevronDownIcon v-if="me" />
      <figure class="icon icon--loading" v-else></figure>
      <div class="header__user-drop" v-if="me">
        <router-link :to="{ name: 'Account' }" class="header__user-link">Профиль</router-link>
        <a class="header__user-link header__user-link--red" @click.prevent="logout">
          <LoadingIndicator title="Подождите" v-if="logoutLoading" />
          <template v-else>Выход</template>
        </a>
      </div>
    </div>
    <Component
      :is="activeDrop.component"
      :options="activeDrop.options"
      v-if="activeDrop"
      @event="handleDropEvent"
    />
  </header>
</template>

<script>
// import SettingsIcon from "./svg/SettingsIcon";
import FullLogoIcon from "./svg/FullLogoIcon";
import FileIcon from "./svg/FileIcon";
import UsersIcon from "./svg/UsersIcon";
import NotificationOnIcon from "./svg/NotificationOnIcon";
import ChevronDownIcon from "./svg/ChevronDownIcon";
import SearchIcon from "./svg/SearchIcon";
import MobileLogoIcon from "./svg/MobileLogoIcon";
import BurgerMenuIcon from "./svg/BurgerMenu";
import SearchComponent from "./SearchComponent.vue";
import NotificationsListComponent from "./NotificationsListComponent.vue";
import ClickOutside from "vue-click-outside";
import LoadingIndicator from "./LoadingIndicator.vue";
import LoaderIcon from "./svg/LoaderIcon.vue";

export default {
  name: "HeaderComponent",
  data() {
    return {
      logoutLoading: false,
      notificationsLoading: false,
      activeDrop: null,
    };
  },
  computed: {
    unreadNotifications() {
      if (this.me && this.me.notifications) {
        return this.me.notifications
          .map((n) => ({
            read_at: n.read_at,
          }))
          .filter((n) => n.read_at === null);
      }
      return [];
    },
    me() {
      // return false;
      return this.$store.state.me;
    },
    showContractors() {
      if (this.me && this.me.role && this.me.role.length) {
        return !!this.me.role.filter((r) => {
          return ["sv", "admin", "supplier_leader", "supplier"].includes(r.name);
        }).length;
      }
      return false;
    },
    showTabs() {
      if (this.me && this.me.role && this.me.role.length) {
        return !!this.me.role.filter((r) => {
          return ["sv", "admin", "supplier_leader", "supplier", "provider"].includes(r.name);
        }).length;
      }
      return false;
    },
  },
  watch: {
    "$route.name"() {
      this.hideDrop();
    },
    "$route.params"() {
      this.hideDrop();
    },
  },
  methods: {
    handleDropEvent(event) {
      switch (event.name) {
        case "notificationsLoading":
          this.notificationsLoading = event.value;
          break;
        case "settingsLoading":
          // TODO
          break;
        default:
          break;
      }
    },
    hideDrop() {
      this.activeDrop = null;
    },
    toggleNotifications() {
      if (this.activeDrop && this.activeDrop.component === NotificationsListComponent) {
        this.hideDrop();
        return;
      }
      this.activeDrop = {
        component: NotificationsListComponent,
        options: {
          rect: this.$refs.notificationsButton.getBoundingClientRect(),
        },
      };
    },
    showSearch() {
      this.hideDrop();
      document.body.classList.add("no-scroll");
      this.$store.state._modals.push({
        component: SearchComponent,
        options: {
          rect: this.$refs.input.getBoundingClientRect(),
        },
      });
    },
    logout() {
      this.logoutLoading = true;
      this.$store
        .dispatch("LOGOUT_USER", {
          context: this,
        })
        .then(() => {
          this.$store.commit("removeAuthToken");
          location.reload();
        })
        .catch(() => {
          this.logoutLoading = false;
        });
    },
  },
  components: {
    LoaderIcon,
    LoadingIndicator,
    NotificationsListComponent,
    BurgerMenuIcon,
    MobileLogoIcon,
    SearchIcon,
    ChevronDownIcon,
    // SettingsIcon,
    NotificationOnIcon,
    UsersIcon,
    FileIcon,
    FullLogoIcon,
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/svg"
.header {
  width 100%
  height 80px
  display grid
  grid-template-columns 180px auto 1fr auto auto
  grid-gap 40px
  background var(--main_white)
  box-shadow: var(--header_shadow);
  padding 0 30px
  z-index: 2;
  position: relative;
  +below(1440px) {
    grid-template-columns 40px auto 1fr auto auto
    grid-gap 20px
  }
  +below(1240px) {
    grid-template-columns 32px auto 40px
    grid-template-rows 70px 80px
    grid-gap unset
    height auto
    padding 0 20px
  }


  &--no-tabs {
    grid-template-columns 180px 1fr auto auto
    +below(1440px) {
      grid-template-columns 40px 1fr auto auto
    }
  }

  .burger-menu-icon {
    width 32px
    display none
    +below(1240px) {
      display flex
    }
  }

  .mobile-logo-icon {
    display none
    width 40px
    height 100%
    +below(1440px) {
      display inline-flex
    }
    +below(1240px) {
      display none
    }
    +below(380px) {
      display inline-flex
      align-items center
      width 100%
      margin auto 0
      svg {
        height 35px
      }
    }
  }

  .full-logo-icon {
    width 180px
    height 100%
    display inline-flex
    align-items center
    +below(1440px) {
      display none
    }
    +below(1240px) {
      display inline-flex
      width 100%
    }
    +below(380px) {
      display none
    }

    svg {
      height 47px
    }
  }

  &__tabs {
    list-style none
    padding 0
    height 100%
    display flex
    +below(1240px) {
      display none
    }
  }

  &__tab {
    height calc(100% + 1px)
    display grid
    grid-template-columns 20px auto
    grid-gap 10px
    padding 0 24px
    align-items center
    justify-content center
    text-decoration none
    font-weight: 700;
    font-size: 0.75em;
    line-height: 22px;
    letter-spacing: var(--letter_spacing_small);
    text-transform: uppercase;
    color: var(--main_default_dark);
    margin-bottom -1px
    +below(1440px) {
      padding 0 20px
    }

    &:hover {
      color var(--main_dark)

      .icon {
        svg(var(--main_dark))
      }
    }

    .icon {
      width 20px
      height 20px

      svg path {
        transition .3s
      }
      svg(var(--main_default_dark))
    }

    &--active {
      border-bottom 1px solid var(--main_dark)
      color var(--main_dark)

      .icon {
        svg(var(--main_dark))
      }

      &:hover {
        border-bottom 1px solid var(--main_dark)
        color var(--main_default_dark)

        .icon {
          svg(var(--main_default_dark))
        }
      }
    }
  }

  &__buttons {
    display grid
    grid-template-columns auto
    grid-gap 40px
    height 100%
    align-items center
    +below(1440px) {
      grid-gap 20px
    }
    +below(1240px) {
      grid-template-columns auto
      & .header__button:not(:first-child) {
        display none
      }
    }
  }

  &__button {
    cursor pointer
    outline none
    background: var(--main_cream);
    border-radius: 100%;
    border none
    display inline-flex
    align-items center
    justify-content center
    flex-shrink 0
    width 40px
    height 40px
    position relative

    &--loading {
      border-radius 100%
      border 1px solid var(--main_light_divider)
      background-color var(--main_light_divider);
      background: linear-gradient(90deg, var(--main_table_header) 0%, var(--main_accent-o12) 50%, var(--main_table_header) 100%);
      background-size: 400% 100%;
      animation: gradient 2s ease infinite;
    }

    &-count {
      absolute top -2px right -2px
      width 18px
      height 18px
      display inline-flex
      align-items center
      justify-content center
      flex-shrink 0
      font-weight: bold;
      font-size: 0.625em;
      line-height: 12px;
      text-align: center;
      color: var(--main_white);
      background: var(--main_red);
      border: 2px solid var(--main_white);
      border-radius 100%
    }

    .icon {
      width 20px
      height 20px
    }
  }

  &__user {
    display grid
    grid-template-columns 40px auto 18px
    grid-gap 8px
    align-items center
    height 100%
    cursor pointer
    position relative
    +below(1240px) {
      display none
    }

    &:focus
    &:hover {
      .header__user-drop {
        visibility visible
        opacity 1
        transform translateY(100%)
      }
    }

    &-name {
      font-size: 0.875em;
      line-height: 18px;
      letter-spacing: var(--letter_spacing);
      color: var(--main_black);

      &--loading {
        height 18px
        width 150px
        border-radius var(--radius)
        border 1px solid var(--main_light_divider)
        background-color var(--main_light_divider);
        background: linear-gradient(90deg, var(--main_table_header) 0%, var(--main_accent-o12) 50%, var(--main_table_header) 100%);
        background-size: 400% 100%;
        animation: gradient 2s ease infinite;
      }
    }

    .icon {
      width 18px
      height 18px

      &--loading {
        border-radius 100%
        border 1px solid var(--main_light_divider)
        background-color var(--main_light_divider);
        background: linear-gradient(90deg, var(--main_table_header) 0%, var(--main_accent-o12) 50%, var(--main_table_header) 100%);
        background-size: 400% 100%;
        animation: gradient 2s ease infinite;
      }
    }

    &-img {
      box-sizing: border-box;
      width 40px
      height 40px

      &--loading {
        border-radius 100%
        background-color var(--main_light_divider);
        background: linear-gradient(90deg, var(--main_table_header) 0%, var(--main_accent-o12) 50%, var(--main_table_header) 100%);
        background-size: 400% 100%;
        animation: gradient 2s ease infinite;
      }

      img {
        border-radius: 100%;
        object-fit cover
        object-position center
        border: 2px solid var(--main_white);
      }
    }
  }


  &__user-drop {
    absolute right bottom
    transform translateY(90%)
    visibility hidden
    opacity 0
    display flex
    flex-direction column
    width 250px
    background var(--main_white)
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 var(--button_small_radius) var(--button_small_radius);
    overflow hidden
    z-index 1
    transition .1s
  }

  &__user-link {
    padding 10px
    font-weight 500
    font-size: 0.8750em;
    line-height: normal;
    letter-spacing: var(--letter_spacing);
    color: var(--main_dark);
    transition .3s

    &:hover {
      background var(--main_green_grass)
      color var(--main_white)
    }

    &--red:hover {
      background var(--main_red)
      color var(--main_white)
    }
  }

  &__search {
    display flex
    padding 16px
    align-items center
    border none
    background: var(--main_cream);
    border-radius: var(--radius);
    height 50px
    margin auto 0
    cursor text
    font-weight: normal;
    font-size: 1em;
    line-height: 20px;
    color: var(--main_gray);
    +below(1240px) {
      grid-row 2
      grid-column 1 / -1
    }

    &-label {
      display: flex;
      align-items: center;
    }

    .icon {
      width 18px
      height 18px
      margin-right 12px
    }
  }
}
</style>
