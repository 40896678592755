import ALL_USERS from "@/graphql/User/All.graphql";
import ALL_USERS_PAGINATE from "@/graphql/User/PaginateUsers.graphql";
import ALL_ROLES from "@/graphql/Roles/All.graphql";
import LOGIN from "@/graphql/User/mutations/Login.graphql";
import LOGOUT from "@/graphql/User/mutations/LogoutUser.graphql";
import CREATE_USER from "@/graphql/User/mutations/Create.graphql";
import RESET_PASSWORD_LINK from "@/graphql/User/mutations/ResetPasswordLink.graphql";
import RESET_USER_PASSWORD from "@/graphql/User/mutations/ResetPassword.graphql";
import EDIT_USER_PROFILE from "@/graphql/User/mutations/EditUserProfile.graphql";
import EDIT_USER from "@/graphql/User/mutations/EditUser.graphql";
import VERIFY_USER from "@/graphql/User/mutations/VerifyUser.graphql";
import MARK_AS_READ_BY_ID from "@/graphql/User/mutations/MarkAsReadById.graphql";
import PAGINATE_NOTIFICATION from "@/graphql/User/PaginateNotifications.graphql";
import REMOVE_AVATAR from "@/graphql/User/mutations/RemoveAvatar.graphql";
import REGISTRATION from "@/graphql/QueriesByPage/registration.graphql";
import PARTNER_EDIT from "@/graphql/QueriesByPage/supplier_edit.graphql";
import CONTRACTORS_LIST_ACTIONS from "@/graphql/QueriesByPage/contractorsListActions.graphql";
import NOTIFICATIONS_LIST_ACTIONS from "@/graphql/User/NotificationFilterData.graphql";
import ME from "@/graphql/User/Me.graphql";

function SET_AUTH_TOKEN({ commit }, token) {
  commit("setAuthToken", token);
}

async function GET_PERMISSIONS({ commit }, { variables = {}, context }) {
  await context.$apollo.mutate({
    mutation: ALL_ROLES,
    variables: Object.assign(variables),
    update: (store, { data }) => {
      commit("setPermissions", data);
    },
  });
}

async function GET_REGISTRATION_PAGE({ commit }, { context }) {
  await context.$apollo.mutate({
    mutation: REGISTRATION,
    update: (store, { data }) => {
      commit("setRegistration", data);
    },
  });
}

async function GET_PARTNER_EDIT_PAGE({ commit }, { context }) {
  await context.$apollo
    .query({
      query: PARTNER_EDIT,
    })
    .then(({ data }) => {
      commit("setPartnerEdit", data);
    });
}

async function GET_ROLES({ commit }, { variables = {}, context }) {
  await context.$apollo.mutate({
    mutation: ALL_ROLES,
    variables: Object.assign(variables),
    update: (store, { data }) => {
      commit("setRoles", data);
    },
  });
}

async function GET_USERS({ commit }, { variables = {}, context }) {
  await context.$apollo.mutate({
    mutation: ALL_USERS,
    variables: Object.assign(variables),
    update: (store, { data }) => {
      commit("setUsers", data);
    },
  });
}

async function GET_USERS_PAGINATE({ commit }, { variables = {}, context }) {
  await context.$apollo.mutate({
    mutation: ALL_USERS_PAGINATE,
    variables: Object.assign(variables),
    update: (store, { data }) => {
      commit("setContractors", data);
    },
  });
}

async function GET_NOTIFICATIONS_PAGINATE({ commit }, { variables = {}, context }) {
  await context.$apollo.mutate({
    mutation: PAGINATE_NOTIFICATION,
    variables: Object.assign(variables),
    update: (store, { data }) => {
      commit("setNotifications", data);
    },
  });
}

async function GET_ME({ commit }, { variables = {}, context }) {
  await context.$apollo.mutate({
    mutation: ME,
    variables: Object.assign(variables),
    update: (store, { data }) => {
      commit("setMe", data);
    },
  });
}

async function ADD_USER(store, { variables = {}, context }) {
  return await context.$apollo.mutate({
    mutation: CREATE_USER,
    variables: Object.assign(variables),
  });
}

async function EDIT_USER_BY_ID(store, { variables = {}, context }) {
  return await context.$apollo.mutate({
    mutation: EDIT_USER,
    variables: Object.assign(variables),
  });
}

async function LOGIN_USER(store, { variables = {}, context }) {
  return await context.$apollo.mutate({
    mutation: LOGIN,
    variables: Object.assign(variables),
  });
}

async function LOGOUT_USER(store, { variables = {}, context }) {
  return await context.$apollo.mutate({
    mutation: LOGOUT,
    variables: Object.assign(variables),
  });
}

async function RESTORE_PASSWORD(store, { variables = {}, context }) {
  return await context.$apollo.mutate({
    mutation: RESET_PASSWORD_LINK,
    variables: Object.assign(variables),
  });
}

async function RESET_PASSWORD(store, { variables = {}, context }) {
  return await context.$apollo.mutate({
    mutation: RESET_USER_PASSWORD,
    variables: Object.assign(variables),
  });
}

async function EDIT_ME(store, { variables = {}, context }) {
  return await context.$apollo.mutate({
    mutation: EDIT_USER_PROFILE,
    variables: Object.assign(variables),
  });
}

async function DELETE_AVATAR(store, { variables = {}, context }) {
  return await context.$apollo.mutate({
    mutation: REMOVE_AVATAR,
    variables: Object.assign(variables),
  });
}

async function VERIFY_CONTRACTORS(store, { variables = {}, context }) {
  return await context.$apollo.mutate({
    mutation: VERIFY_USER,
    variables: Object.assign(variables),
  });
}

async function MARK_NOTIFICATION_READ(store, { variables = {}, context }) {
  return await context.$apollo.mutate({
    mutation: MARK_AS_READ_BY_ID,
    variables: Object.assign(variables),
  });
}

async function GET_CONTRACTORS_LIST_ACTIONS_DATA({ commit }, { context }) {
  return await context.$apollo.mutate({
    mutation: CONTRACTORS_LIST_ACTIONS,
    update: (store, { data }) => {
      commit("setContractorsListActions", data);
    },
  });
}

async function GET_NOTIFICATIONS_LIST_ACTIONS_DATA(_, { context }) {
  return await context.$apollo.mutate({
    mutation: NOTIFICATIONS_LIST_ACTIONS,
  });
}

export {
  GET_NOTIFICATIONS_LIST_ACTIONS_DATA,
  GET_CONTRACTORS_LIST_ACTIONS_DATA,
  VERIFY_CONTRACTORS,
  SET_AUTH_TOKEN,
  MARK_NOTIFICATION_READ,
  GET_USERS,
  ADD_USER,
  GET_ME,
  LOGIN_USER,
  LOGOUT_USER,
  RESTORE_PASSWORD,
  RESET_PASSWORD,
  GET_USERS_PAGINATE,
  GET_REGISTRATION_PAGE,
  GET_PARTNER_EDIT_PAGE,
  EDIT_ME,
  DELETE_AVATAR,
  GET_NOTIFICATIONS_PAGINATE,
  EDIT_USER_BY_ID,
  GET_ROLES,
  GET_PERMISSIONS,
};
