<template>
  <figure class="icon mobile-logo-icon">
    <svg width="39" height="35" viewBox="0 0 39 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.35627 34.224H0.209717L7.35627 21.5956V34.224Z"
        fill="#005690"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.5354 19.3253H25.5475V26.4666L18.5354 19.3253Z"
        fill="#14804A"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.3123 34.224H12.8562V21.5499L25.3123 34.224Z"
        fill="#005690"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.0474 21.5044L38.1939 34.2241H31.0474V21.5044Z"
        fill="#005690"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.5475 17.4774H16.7207L12.901 13.5759H25.5475V17.4774Z"
        fill="#67A74A"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.3409 7.76929L25.5476 11.705V11.7392H12.9347L15.175 7.76929H23.3409Z"
        fill="#8DBC3B"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.2747 0.525391L22.5007 6.26349H16.0262L19.2747 0.525391Z"
        fill="#F4E704"
      ></path>
    </svg>
  </figure>
</template>

<script>
export default {
  name: "MobileLogoIcon",
};
</script>
